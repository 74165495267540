import { z } from 'zod'
import { CurrencyCodesSchema } from '../../country-currency-codes'
import {
  MediaSchema,
  PriceLadderSchema,
  RatingSchema,
  CustomerReviewSchema,
  DurationSchema,
} from '../../common'
import { AddressSchema } from '../../hotel/address'

export const ExperienceDetailsTypeEnum = z.enum([
  'STANDARD',
  'MULTI_DAY_TOUR',
  'ACTIVITY',
  'HOP_ON_HOP_OFF',
  'UNSTRUCTURED',
])

const ExperienceGroupKeyEnumSchema = z.enum([
  'adult',
  'child',
  'infant',
  'youth',
  'senior',
  'traveler',
])

export const DishTypeEnumSchema = z.enum([
  'BREAKFAST',
  'LUNCH',
  'DINNER',
  'SNACKS',
  'BOTTLED_WATER',
  'COFFEE_AND_TEA',
  'SODA_POP',
  'ALCOHOLIC_BEVERAGES',
  'REFRESHMENTS',
  'MAIN',
  'STARTER',
  'DESSERT',
])

export const AdmissionIncludedSchema = z.enum(['YES', 'NO', 'NOT_APPLICABLE'])

const ExperienceGroupSchema = z.object({
  key: ExperienceGroupKeyEnumSchema,
  ageRange: z.array(z.number(), z.number()),
  minCount: z.number(),
  maxCount: z.number(),
})

const HopOnHopOffItemSchema = z.object({
  name: z.string().optional(),
  duration: DurationSchema.optional(),
  operatingSchedule: z.string().optional(),
  pointsOfInterest: z.array(z.string()).optional(),
  stopsList: z.array(z.string()).optional(),
})

const FoodMenuItemSchema = z.object({
  dishType: DishTypeEnumSchema,
  dishName: z.string(),
  dishDescription: z.string(),
})

const ActivityItemSchema = z.object({
  locationDescription: z.string().optional(),
  foodMenu: z.array(FoodMenuItemSchema).optional(),
})

const WhatToExpectItemSchema = z.object({
  title: z.string().optional(),
  description: z.string(),
  passByWithoutStopping: z.boolean().optional(),
  duration: DurationSchema.optional(),
  admissionIncluded: AdmissionIncludedSchema.optional(),
})

const ExperienceLocationSchema = z.object({
  address: AddressSchema.optional(),
  location: z.string().optional(),
  description: z.string().optional(),
})

const DeliveryInstructionsSchema = z.object({
  pickupLocations: ExperienceLocationSchema.array().optional(),
  startLocations: ExperienceLocationSchema.array().optional(),
  endLocations: ExperienceLocationSchema.array().optional(),
})

export const ExperienceDetailsResultSchema = z.object({
  bundleId: z.string().optional(),
  name: z.string().optional(),
  productId: z.string().optional(),
  vendorName: z.string().optional(),
  type: ExperienceDetailsTypeEnum.optional(),
  whatToExpect: z.array(WhatToExpectItemSchema).optional(),
  languagesOffered: z.array(z.string()).optional(),
  guestRatings: RatingSchema.optional(),
  experienceDuration: DurationSchema.optional(),
  cancellationPolicy: z.string().optional(),
  cancelIfBadWeather: z.boolean().optional(),
  cancelIfInsufficientTravelers: z.boolean().optional(),
  description: z.string().optional(),
  information: z
    .object({
      inclusions: z.array(z.string()).optional(),
      exclusions: z.array(z.string()).optional(),
      tags: z.array(z.string()).optional(),
      additionalInfo: z.array(z.string()).optional(),
    })
    .optional(),
  cashPrice: z.number(),
  aviosPrice: z.number(),
  collectAmount: z.number(),
  currencyCode: CurrencyCodesSchema,
  pricingLadder: z.array(PriceLadderSchema).optional(),
  customerReviews: z.array(CustomerReviewSchema),
  media: z.array(MediaSchema).optional(),
  travelers: z.object({
    max: z.number(),
    min: z.number(),
    adultRequired: z.boolean(),
    groups: z.array(ExperienceGroupSchema),
  }),
  hopOnHopOffItems: z.array(HopOnHopOffItemSchema).optional(),
  activity: ActivityItemSchema.optional(),
  availableDays: z.array(z.number()).optional(),
  deliveryInstructions: DeliveryInstructionsSchema.optional(),
})

export const ExperienceDetailsDataTransferObjectSchema = z.object({
  correlationId: z.string(),
  recommendationId: z.string(),
  result: ExperienceDetailsResultSchema,
})

export type ExperienceDetailsDataTransferObject = z.infer<
  typeof ExperienceDetailsDataTransferObjectSchema
>
export type HopOnHopOffItem = z.infer<typeof HopOnHopOffItemSchema>
export type Activity = z.infer<typeof ActivityItemSchema>
export type FoodMenuItem = z.infer<typeof FoodMenuItemSchema>
export type WhatToExpectItem = z.infer<typeof WhatToExpectItemSchema>
export type ExperienceLocation = z.infer<typeof ExperienceLocationSchema>
export type DeliveryInstructions = z.infer<typeof DeliveryInstructionsSchema>
